import React from 'react';
import {ConsulatationButton, ExposureRow} from './priceSection';

const blackColorStyle = {color: 'rgb(0, 0, 0)'};

class SliderPage extends React.Component {
	render() {
		let number = this.props.number;
		let sliderPageClassName = 'slider2-page' + (number === 1 ? ' is-active' : '');
		return (
			<div plp-slider-page=""
			     className={sliderPageClassName}>
				<div plp-slider-page_number=""
				     className="slider2-page_number">
					{number}
				</div>
			</div>
		);
	}
}

class SliderPaginator extends React.Component {
	renderSliderPages(data) {
		let pagesArray = [];
		if (data.length < 2) return [];
		data.forEach((item, index) => {
			let number = index + 1;
			let key = "SliderPage"+number+Math.random();
				pagesArray.push(<SliderPage key = {key} number={number} />);
			}
		);
		return pagesArray;
	}

	render() {
		let data = this.props.data ? this.props.data : [];
		const className = data && data.length > 1 ? 'slider2-pagination' : '';
		return (
			<div plp-slider-pagination=""
			     className={className}>
				{this.renderSliderPages(data)}
			</div>
		);
	}
}

class SwiperSlide extends React.Component {
	render() {
		let img = this.props.img ? this.props.img : '/img/c0add2cac1b9eb8fb8812f872397fd36.jpg';
		let adaptive = this.props.adaptive;
		let bgimageStyle = {
			height: adaptive ? 220 : 400,
			backgroundPosition: 'center center',
			backgroundSize: 'contain',
			backgroundImage: "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAH0lEQVQ4T2NkoBAwUqifYdQAhtEwYBgNA1A+Gvi8AAAmmAARf9qcXAAAAABJRU5ErkJggg==')"
		};

		return (
			<div className="swiper-slide">
				<div className="cont-wrapper">
					<div className="cont">
						<div className="node node344 widget widget-image">
							<div className="wrapper1">
								<div className="wrapper2">
									<a className="bgimage fancybox"
									   href={img}
									   data-group="1"
									   style={bgimageStyle}
									   data-lazy-bgimage={img}
									   data-lazy-bgimage_size="801,801"></a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

class SliderRoot extends React.Component {
	renderSlides(data) {
		let slidesArray = [];
		let adaptive =this.props.adaptive;
		data.forEach((item, index) => {
			let key = 'SwiperSlide' + index + Math.random();
			slidesArray.push(<SwiperSlide key = {key} img={item} adaptive = {adaptive}/>);
		});
		return slidesArray;
	}

	render() {
		let animationDelay = 5000;
		let data = this.props.data;
		let groupNumber = this.props.groupNumber ? this.props.groupNumber : 1;
		let sliderPrev = data && data.length > 1 ? [
			<div plp-slider-previous=""
			     className="slider2-previous">
			<i className="fa fa-chevron-left"></i>
		</div>] : [];
		let sliderNext = data && data.length > 1 ? [
			<div plp-slider-next=""
			      className="slider2-next">
			<i className="fa fa-chevron-right"></i>
		</div>] : '';
		return (
			<div className="slider2-root">
				{sliderPrev}
				<div plp-slider-container=""
				     data-red-libs="swiper"
				     data-pause={animationDelay}
				     data-animated="true"
				     data-fixheight="true"
				     className="slider2-container swiper-container lazy-all">
					<div className="swiper-wrapper">
						{this.renderSlides(data)}
					</div>
				</div>
				{sliderNext}
			</div>
		);
	}
}

class SwiperColumn extends React.Component {
	renderInner(data) {
		let innerArray = [];
		let adaptive = this.props.adaptive;
		let sliderPaginatorKey = 'SliderPaginator' + data.length + Math.random();
		let sliderRootKey = 'SliderRoot' + data.length + Math.random();
		innerArray.push(<SliderPaginator key = {sliderPaginatorKey} data={data} adaptive={adaptive}/>);
		innerArray.push(<SliderRoot key={sliderRootKey} data={data} adaptive={adaptive}/>);
		return innerArray;
	}

	render() {
		let data = this.props.data ? this.props.data : [];
		return (
			<div className="col"
			     style={{width: '46.3207%'}}>
				<div className="cont">
					<div className="node node383 widget widget-slider">
						<div className="wrapper1">
							<div className="wrapper2">
								<div className="metahtml">
									<div id="node383_meta"
									     className="code">
										{this.renderInner(data)}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

class DescriptionColumn extends React.Component {
	renderDescriptionList(data) {
		if (!data.length) return;
		let rows = [];
		let returnRows = [];
		let adaptive = this.props.adaptive;
		let style = {
			fontSize: adaptive ? 12 : 16
		};
		data.forEach((item, index) => {
			let name = '';
			let value = '';

			if (typeof item === 'string') {
				value = item;
			} else {
				name = item.name ? item.name + ':' : '';
				value = item.value ? ' ' + item.value : '';
			}

			if (adaptive) {
				rows.push(
				<p key={'DescriptionListElP' + index + Math.random()} style={{lineHeight: 1}}>
					<span style={style}>
						<strong>{name}</strong>{value}
					</span>
				</p>
				);
			} else {
				rows.push(
					<li key={'DescriptionListElLi' + index + Math.random()} dir="ltr">
					<span style={style}>
						<strong>{name}</strong>{value}
					</span>
					</li>
				);
			}
		});

		if (!adaptive) {
			returnRows.push(
				<ul key = {'DescriptionListElUl'+Math.random()}>
					{rows}
				</ul>
			);
		} else {
				returnRows = rows;
		}
		return returnRows;
	}

	renderDescriptionText(string) {
		return !string ? [] : (
			<p dir="ltr">
				{string}
			</p>
		);
	}

	render() {
		let adaptive = this.props.adaptive;
		const titleStyle = {
			color: 'rgb(0, 0, 0)',
			fontSize: adaptive ? 16 : 28,
			fontFamily: 'TTNorms Bold'
		};

		let title = this.props.title ? this.props.title : '';
		let description = this.props.description ? this.props.description : '';
		let data = this.props.list ? this.props.list : [];

		return (
			<div className="col"
			     style={{width: '53.6793%'}}>
				<div className="cont">
					<div className="node node350 widget widget-element">
						<div className="wrapper1">
							<div className="wrapper2">
								<div className="cont">
									<div className="node node352 widget widget-text">
										<div className="wrapper1">
											<div className="wrapper2">
												<div className="">
													<div className="textable">
														<p>
										          <span style={titleStyle}>
											          {title}
										          </span>
														</p>
														{this.renderDescriptionText(description)}
															<p/>
															{this.renderDescriptionList(data)}
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<br/>
					<ConsulatationButton product = {title} text="РАССЧИТАТЬ СТОИМОСТЬ ПОХОЖЕЙ ЛЕСТНИЦЫ"/>
				</div>
			</div>
		);
	}
}

class AbstractPortfolioItem extends React.Component {
	render() {
		let images = this.props.images ? this.props.images : [];
		let title = this.props.title ? this.props.title : 'Заголовок';
		let description = this.props.description ? this.props.description : '';
		let list = this.props.list ? this.props.list : [];
		let adaptive = this.props.adaptive;
		// <div className="node node341 widget widget-element">
		return (
			<div className="wrapper1">
				<div className="wrapper2">
					<div className="cont">
						<div className="node node342 widget widget-grid">
							<div className="wrapper1">
								<div className="wrapper2">
									<div className="grid valign-middle paddings-20px xs-wrap">
										<div className="gridwrap">
											<SwiperColumn data={images} adaptive={adaptive}/>
											<DescriptionColumn title = {title} description = {description} list = {list} adaptive={adaptive}/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

class BlankItem extends React.Component {
	render() {
		return (
			<div className="node node360 widget widget-blank">
				<div className="wrapper1">
					<div className="wrapper2">
						<div className="padding"/>
					</div>
				</div>
			</div>
		);
	}
}


class PortfolioPaginator extends React.Component {
	renderPageTitles(data) {
		let pageTitlesArray = [];
		let adaptive = this.props.adaptive;
		let style = adaptive ? {fontSize: 12} : {};

		data.forEach((item, index) => {
			let mainClassName = index === 0 ? 'tabs4-page is-active' : 'tabs4-page';
			let title = typeof item === 'string' ? item : item.title;
			let key = 'PortfolioPaginator'+mainClassName+index+Math.random();
			pageTitlesArray.push(
				<div key ={key} plp-slides-page="" className={mainClassName}>
					<div plp-slides-page_label="" className="tabs4-label">
						<div style = {style} className="textable">
							<p>{title}</p>
						</div>
					</div>
				</div>
			);
		});

		return pageTitlesArray;
	}

	render() {
		let pages = this.props.pages;

		return (
			<div plp-slides-pagination="" className="tabs4-pagination">
				{this.renderPageTitles(pages)}
			</div>
		);
	}
}

/**
 * Корень "страниц" ("глав") портфолио,
 */
class PortfolioPagesRoot extends React.Component {
	renderPages(pages) {
		return pages.map(item => item.page);
	}
	render() {
		let pages = this.props.pages;
		let adaptive = this.props.adaptive;
		//let slidesContainerClassName = adaptive ? 'tabs4-container swiper-container lazy-all swiper-container-fade swiper-container-horizontal swiper-container-autoheight' : 'tabs4-container swiper-container lazy-all';
		let slidesContainerClassName = 'tabs4-container swiper-container lazy-all';
		return (
			<div className="tabs4-root">
				<div plp-slides-container="" data-red-libs="swiper"
				     data-animated="false" data-fixheight="false"
				     className={slidesContainerClassName}>
					<div className="swiper-wrapper">
						{this.renderPages(pages)}
					</div>
				</div>
			</div>
		);
	}
}

class PortfolioBlockPages extends React.Component {
	render() {
		let pages = this.props.pages;
		let adaptive = this.props.adaptive;
		let nodeNumber = adaptive ? 729 : 340;
		let className = `node node${nodeNumber} widget widget-tabs`
		let id = `node${nodeNumber}_meta`;
		return (
			<div className={className}>
				<div className="wrapper1">
					<div className="wrapper2">
						<div className="metahtml">
							<div id={id} className="code">
								<PortfolioPaginator pages = {pages} adaptive = {adaptive}/>
								<PortfolioPagesRoot pages = {pages} adaptive = {adaptive}/>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

class PortfolioBlockText extends React.Component {
	renderText() {
		let title = this.props.title && this.props.title.length ? this.props.title : '';
		let text = this.props.text && this.props.text.length ? this.props.text : '';
		let adaptive = !!this.props.adaptive;

		let titleStyle = {
			lineHeight: 1.3,
			textAlign: 'center',
			color: 'rgb(0, 0, 0)',
			fontSize: adaptive ? 22 : 40,
			fontFamily: 'TTNorms Bold'
		};

		let textStyle = {
			lineHeight: 1.3,
			textAlign: 'center',
			fontFamily: 'TTNorms Light',
			fontSize: adaptive ? 22 : 18,
			color: blackColorStyle
		};
		let returnArray = [];

		if (typeof title === 'string') {
			let key = "PortfolioBlockTextTitle"+Math.random();
			returnArray.push(
				<span key = {key} style={titleStyle} className="p">
          {title}
				</span>
			);
		} else {
			returnArray = title.map((item, index) =>
				<span key = {"PortfolioBlockTextTitle"+index+Math.random()} style={titleStyle} className="p">
          {item}
				</span>);
		}

		if (typeof text === 'string') {
			let key = "PortfolioBlockTextText"+Math.random();
			returnArray.push(
				<span key = {key} style={textStyle} className="p">
          {text}
				</span>
			);
		} else {
			text.forEach((item, index) => returnArray.push(
				<span key = {"PortfolioBlockTextText"+index+Math.random()} style={textStyle} className="p">
          {item}
				</span>
			));
		}

		return (
			<div className="xs-scale-80">
				<h1 className="font-header spans textable">
					{returnArray}
				</h1>
			</div>
		);

	}
	render() {
		// <div className="node node339 widget widget-text">
		return (
				<div className="wrapper1">
					<div className="wrapper2">
						{this.renderText()}
					</div>
				</div>
		);
	}
}

class PortfolioBlockHeader extends React.Component {
	render() {
		let title = ['Наш Опыт и Знания по изготовлению и монтажу', 'Лестниц под Ключ - с 2006г., более 13 лет'];
		let text = 'Вы можете ознакомиться с нашими работами в офисе продаж.';
		let adaptive = this.props.adaptive;
		let data = {
			title: title,
			text: text,
			adaptive: adaptive
		};

		return (
			<div className="node node728 widget widget-text">
				<PortfolioBlockText {...data}/>
			</div>
		);
	}
}

class PortfolioBlockAdditionalText extends React.Component {
	render() {
		let adaptive = this.props.adaptive;
		let title = this.props.title ? this.props.title : ['Изготавливаем и другие предметы интерьера'];
		let text = (this.props.title ? (this.props.text ? this.props.text : ['']) : ['кухонные и спальные гарнитуры, двери, барные стойки, рабочие кабинеты, детские, столы для бильярда.']);
		if (!adaptive && Array.isArray(text)) {
			text.push('Они будут гармонично сочетаться с вашей любимой лестницей.');
			text.push('Занимаемся реставрацией старой мебели');
		}
		let data = {
			title: title,
			text: text,
			adaptive: adaptive
		};

		return (
			<div className="node node537 widget widget-text">
				<PortfolioBlockText {...data}/>
			</div>
		);
	}
}

class PortfolioBottomGallery extends React.Component {
	renderCols(data) {
		let rendered = [];
		let bgimageStyle = {
			height: 348,
			backgroundPosition: 'center center',
			backgroundSize: 'cover',
			backgroundImage: "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAH0lEQVQ4T2NkoBAwUqifYdQAhtEwYBgNA1A+Gvi8AAAmmAARf9qcXAAAAABJRU5ErkJggg==')"
		};

		data.forEach((item, index) => {
			item = (typeof item === 'string' ? item : item.img);
			let key = 'PortfolioBottomGallery'+index;
			rendered.push(
				<div key={key} className="col" style={{width: '100%'}}>
					<div className="cont">
						<div className="node node538 widget widget-image">
							<div className="wrapper1">
								<div className="wrapper2">
									<div className="bgimage"
									     style={bgimageStyle}
									     data-lazy-bgimage={item}
									     data-lazy-bgimage_size="1080,1080">
										<img className=""
                     src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                     alt=""/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			);
		});
		return rendered;
	}
	render() {
		let data = this.data ? this.data : [
			'/img/8-1.jpg',
			'/img/8-2.jpg',
			'/img/8-3.jpeg',
			'/img/8-4.png'
		];
		return (
			<div className="node node537 widget widget-grid">
				<div className="wrapper1">
					<div className="wrapper2">
						<div className="grid valign-middle paddings-20px xs-wrap">
							<div className="gridwrap">
								{this.renderCols(data)}
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

class PortfolioSpoiler extends React.Component {
	render() {
		let items = this.props.items;
		//<div className="node node749 widget widget-spoiler">
		return (
				<div className="wrapper1">
					<div className="wrapper2">
						<div className="metahtml">
							<div id="node749_meta" className="code">
								<div className="spoiler2">
									<div plp-spoiler-toggle=""
									     il-action="toggle"
									     className="spoiler2-header is-collapsed">
										<div className="textable">
											<p style={{letterSpacing: '0.1em'}}>
											<span style={{fontSize: 12}}>ПОКАЗАТЬ ЕЩЕ КЕЙСЫ</span>
											</p>
										</div>
										<div className="spoiler2-toggle">
											<i className="fa fa-chevron-down"></i>
										</div>
									</div>
									<div plp-spoiler-container=""
									     className="spoiler2-container is-collapsed"
									     style={{display: 'none'}}>
										<div className="cont">
											{items}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
		);
	}
}


class FirstPageFirstPortfolioItem extends React.Component {
	render() {
		let images = [
			'/img/7-1.jpg',
		];

		let title = 'П-образная, лиственница, 19 ступеней';
		let description = 'Двутонная П-образная лестница с площадкой на 19 ступеней из массива лиственницы';
		let list = [
			{name: 'Ширина марша', value: '950 мм'},
			{name: 'Шаговая часть ступени', value: '295 мм'},
			{name: 'Ступеней', value: '19 шт и площадка'},
			{name: 'Материал ', value: 'лиственница'},
			{name: 'Балюстрада', value: '1 м'},
		];
		let adaptive = this.props.adaptive;

		let data = {
			images: images,
			title: title,
			description: description,
			list: list,
			adaptive: adaptive
		};
		let nodeNumber = adaptive ? 730 : 341;
		let className = `node node${nodeNumber} widget widget-element`

		return (
			<div className={className}>
				<AbstractPortfolioItem {...data} />
			</div>
		);
	}
}

class FirstPageSecondPortfolioItem extends React.Component {
	render() {
		let images = [
			'/img/c0add2cac1b9eb8fb8812f872397fd36.jpg',
			'/img/c0add2cac1b9eb8fb8812f872397fd36.jpg',
			'/img/c0add2cac1b9eb8fb8812f872397fd36.jpg'
		];

		let title = 'Страница 1 Название 2';
		let description = 'П-образная лестница с площадкой, массив бука. Балясины и столбы: "Англия". Цвет подобран под интерьер заказчика';
		let list = [
			{name: 'Свойство 1', value: 'Значение 1'},
			{name: 'Свойство 2', value: 'Значение 2'},
			{name: 'Свойство 3', value: 'Значение 3'},
			{name: 'Свойство 4', value: 'Значение 4'},
			{name: 'Свойство 5', value: 'Значение 5'},
			{name: 'Свойство 6', value: 'Значение 6'}
		];

		let data = {
			images: images,
			title: title,
			description: description,
			list: list
		};

		return (
			<div className="node node341 widget widget-element">
				<AbstractPortfolioItem {...data} />
			</div>
		);
	}
}

class FirstPageThirdPortfolioItem extends React.Component {
	render() {
		let images = [
			'/img/fedc7a93bbcb76c54e50963f6504f640.jpg',
			'/img/fedc7a93bbcb76c54e50963f6504f640.jpg',
			'/img/fedc7a93bbcb76c54e50963f6504f640.jpg'
		];

		let title = 'Страница 1 Название 3';
		let description = 'П-образная лестница с площадкой, массив бука. Балясины и столбы: "Англия". Цвет подобран под интерьер заказчика';
		let list = [
			{name: 'Свойство 1', value: 'Значение 1'},
			{name: 'Свойство 2', value: 'Значение 2'},
			{name: 'Свойство 3', value: 'Значение 3'},
			{name: 'Свойство 4', value: 'Значение 4'},
			{name: 'Свойство 5', value: 'Значение 5'},
			{name: 'Свойство 6', value: 'Значение 6'}
		];

		let data = {
			images: images,
			title: title,
			description: description,
			list: list
		};

		return (
			<div className="node node341 widget widget-element">
				<AbstractPortfolioItem {...data} />
			</div>
		);
	}
}


class SecondPageFirstPortfolioItem extends React.Component {
	render() {
		let images = [
			'/img/7-2.jpg',
		];

		let title = 'Г-образная лестница на 13 ступеней. Дуб';
		let description = 'Г-образная лестницы на 13 ступеней из массива дуба';
		let list = [
			{name: 'Ширина марша', value: '950 мм'},
			{name: 'Ступеней', value: '13'},
			{name: 'Материал ', value: 'дерево Дуб'},
			{name: 'Балюстрада', value: '200 мм'},
		];
		let adaptive = this.props.adaptive;

		let data = {
			images: images,
			title: title,
			description: description,
			list: list,
			adaptive: adaptive
		};
		let nodeNumber = adaptive ? 730 : 341;
		let className = `node node${nodeNumber} widget widget-element`

		return (
			<div className={className}>
				<AbstractPortfolioItem {...data} />
			</div>
		);
	}
}

class SecondPageSecondPortfolioItem extends React.Component {
	render() {
		let images = [
			'/img/c0add2cac1b9eb8fb8812f872397fd36.jpg',
			'/img/c0add2cac1b9eb8fb8812f872397fd36.jpg',
			'/img/c0add2cac1b9eb8fb8812f872397fd36.jpg'
		];

		let title = 'Страница 2 Название 2';
		let description = 'П-образная лестница с площадкой, массив бука. Балясины и столбы: "Англия". Цвет подобран под интерьер заказчика';
		let list = [
			{name: 'Свойство 1', value: 'Значение 1'},
			{name: 'Свойство 2', value: 'Значение 2'},
			{name: 'Свойство 3', value: 'Значение 3'},
			{name: 'Свойство 4', value: 'Значение 4'},
			{name: 'Свойство 5', value: 'Значение 5'},
			{name: 'Свойство 6', value: 'Значение 6'}
		];

		let data = {
			images: images,
			title: title,
			description: description,
			list: list
		};

		return (
			<div className="node node341 widget widget-element">
				<AbstractPortfolioItem {...data} />
			</div>
		);
	}
}

class SecondPageThirdPortfolioItem extends React.Component {
	render() {
		let images = [
			'/img/fedc7a93bbcb76c54e50963f6504f640.jpg',
			'/img/fedc7a93bbcb76c54e50963f6504f640.jpg',
			'/img/fedc7a93bbcb76c54e50963f6504f640.jpg'
		];

		let title = 'Страница 2 Название 3';
		let description = 'П-образная лестница с площадкой, массив бука. Балясины и столбы: "Англия". Цвет подобран под интерьер заказчика';
		let list = [
			{name: 'Свойство 1', value: 'Значение 1'},
			{name: 'Свойство 2', value: 'Значение 2'},
			{name: 'Свойство 3', value: 'Значение 3'},
			{name: 'Свойство 4', value: 'Значение 4'},
			{name: 'Свойство 5', value: 'Значение 5'},
			{name: 'Свойство 6', value: 'Значение 6'}
		];

		let data = {
			images: images,
			title: title,
			description: description,
			list: list
		};

		return (
			<div className="node node341 widget widget-element">
				<AbstractPortfolioItem {...data} />
			</div>
		);
	}
}

class ThirdPageFirstPortfolioItem extends React.Component {
	render() {
		let images = [
			'/img/7-3.jpg',
		];

		let title = 'Прямая лестница на 15 ступеней. Ламельный Дуб';
		let description = 'Прямая лестница на 15 ступеней из ламельного дуба';
		let list = [
			{name: 'Ширина марша', value: '950 мм'},
			{name: 'Ступеней', value: '15'},
			{name: 'Материал ', value: 'Ламельный Дуб'},
			{name: 'Балюстрада', value: '250 мм'},
		];
		let adaptive = this.props.adaptive;

		let data = {
			images: images,
			title: title,
			description: description,
			list: list,
			adaptive: adaptive
		};
		let nodeNumber = adaptive ? 730 : 341;
		let className = `node node${nodeNumber} widget widget-element`

		return (
			<div className={className}>
				<AbstractPortfolioItem {...data} />
			</div>
		);
	}
}

class ThirdPageSecondPortfolioItem extends React.Component {
	render() {
		let images = [
			'/img/c0add2cac1b9eb8fb8812f872397fd36.jpg',
			'/img/c0add2cac1b9eb8fb8812f872397fd36.jpg',
			'/img/c0add2cac1b9eb8fb8812f872397fd36.jpg'
		];

		let title = 'Страница 3 Название 2';
		let description = 'П-образная лестница с площадкой, массив бука. Балясины и столбы: "Англия". Цвет подобран под интерьер заказчика';
		let list = [
			{name: 'Свойство 1', value: 'Значение 1'},
			{name: 'Свойство 2', value: 'Значение 2'},
			{name: 'Свойство 3', value: 'Значение 3'},
			{name: 'Свойство 4', value: 'Значение 4'},
			{name: 'Свойство 5', value: 'Значение 5'},
			{name: 'Свойство 6', value: 'Значение 6'}
		];

		let data = {
			images: images,
			title: title,
			description: description,
			list: list
		};

		return (
			<div className="node node341 widget widget-element">
				<AbstractPortfolioItem {...data} />
			</div>
		);
	}
}

class ThirdPageThirdPortfolioItem extends React.Component {
	render() {
		let images = [
			'/img/fedc7a93bbcb76c54e50963f6504f640.jpg',
			'/img/fedc7a93bbcb76c54e50963f6504f640.jpg',
			'/img/fedc7a93bbcb76c54e50963f6504f640.jpg'
		];

		let title = 'Страница 3 Название 3';
		let description = 'П-образная лестница с площадкой, массив бука. Балясины и столбы: "Англия". Цвет подобран под интерьер заказчика';
		let list = [
			{name: 'Свойство 1', value: 'Значение 1'},
			{name: 'Свойство 2', value: 'Значение 2'},
			{name: 'Свойство 3', value: 'Значение 3'},
			{name: 'Свойство 4', value: 'Значение 4'},
			{name: 'Свойство 5', value: 'Значение 5'},
			{name: 'Свойство 6', value: 'Значение 6'}
		];

		let data = {
			images: images,
			title: title,
			description: description,
			list: list
		};

		return (
			<div className="node node341 widget widget-element">
				<AbstractPortfolioItem {...data} />
			</div>
		);
	}
}

/*
						<BlankItem/>
						<FirstPageSecondPortfolioItem/>
						<BlankItem/>
						<FirstPageThirdPortfolioItem/>*/
class FirstPortfolioPage extends React.Component {
	render() {
		return (
			<div className="swiper-slide">
				<div className="cont-wrapper">
					<div className="cont">
						<FirstPageFirstPortfolioItem/>
					</div>
				</div>
			</div>
		);
	}
}

/*
<SecondPageFirstPortfolioItem/>
						<BlankItem/>
						<SecondPageSecondPortfolioItem/>
						<BlankItem/>
						<SecondPageThirdPortfolioItem/>
*/
class SecondPortfolioPage extends React.Component {
	render() {
		return (
			<div className="swiper-slide">
				<div className="cont-wrapper">
					<div className="cont">
						<SecondPageFirstPortfolioItem/>
					</div>
				</div>
			</div>
		);
	}
}

/*<ThirdPageFirstPortfolioItem/>
						<BlankItem/>
						<ThirdPageSecondPortfolioItem/>
						<BlankItem/>
						<ThirdPageThirdPortfolioItem/>
						*/
class ThirdPortfolioPage extends React.Component {
	render() {
		return (
			<div className="swiper-slide">
				<div className="cont-wrapper">
					<div className="cont">
						<ThirdPageFirstPortfolioItem/>
					</div>
				</div>
			</div>
		);
	}
}

class AdaptiveSpoilerBlock extends React.Component {
	render() {
		let adaptive = true;
		let items = [
			<FirstPageFirstPortfolioItem key={'FirstPageFirstPortfolioItem1'} adaptive={adaptive}/>,
			<FirstPageFirstPortfolioItem key={'FirstPageFirstPortfolioItem2'} adaptive={adaptive}/>,
			<FirstPageFirstPortfolioItem key={'FirstPageFirstPortfolioItem3'} adaptive={adaptive}/>,
		];
		return (
			<div className="node node749 widget widget-spoiler">
				<PortfolioSpoiler items = {items}/>
			</div>

		);
	}
}

class AdaptiveBottomExposureRow extends React.Component {

	render() {
		let adaptive = false;
		let className = this.props.className ? this.props.className : 'node node212 widget widget-grid';
		let firstRowData = [
			{img: '/img/8-1.jpg', name: '', cover: true},
			{img: '/img/8-2.jpg', name: '', cover: true}
		];
		let secondRowData = [
			{img: '/img/8-3.jpeg', name: '', cover: true},
			{img: '/img/8-4.png', name: '', cover: true}
		];

		return (
			<div className={className}>
				<ExposureRow adaptive = {adaptive} data={firstRowData}/>
				<ExposureRow adaptive = {adaptive} data={secondRowData}/>
			</div>
		);
	}
}

class AdaptiveFirstPortfolioPage extends React.Component {
	render() {
		let adaptive = true;
		return (
			<div className="swiper-slide">
				<div className="cont-wrapper">
					<div className="cont">
						<FirstPageFirstPortfolioItem  adaptive={adaptive}/>
						<BlankItem/>
						<AdaptiveSpoilerBlock/>
					</div>
				</div>
			</div>
		);
	}
}

class AdaptiveSecondPortfolioPage extends React.Component {
	render() {
		let adaptive = true;
		return (
			<div className="swiper-slide">
				<div className="cont-wrapper">
					<div className="cont">
						<SecondPageFirstPortfolioItem  adaptive={adaptive}/>
						<BlankItem/>
						<AdaptiveSpoilerBlock/>
					</div>
				</div>
			</div>
		);
	}
}

export class PortfolioSection extends React.Component{
	render() {
		let pages = [
			{page: <FirstPortfolioPage key={'FirstPortfolioPage'}/>, title: 'Цельнодеревянная'},
			{page: <SecondPortfolioPage key={'SecondPortfolioPage'}/>, title: 'На металлокаркасе'},
			{page: <ThirdPortfolioPage key={'ThirdPortfolioPage'}/>, title: 'На бетоне'},
		];
		return (
			<div className="cont">
				<PortfolioBlockHeader />
				<PortfolioBlockPages pages = {pages}/>
				<PortfolioBlockAdditionalText/>
				<PortfolioBottomGallery/>
			</div>
		);
	}
}

export class AdaptivePortfolioSection extends React.Component{
	render() {
		let adaptive = true;
		let pages = [
			{page: <AdaptiveFirstPortfolioPage key={'AdaptiveFirstPortfolioPage'}/>, title: 'Деревянные'},
			{page: <AdaptiveSecondPortfolioPage key={'AdaptiveSecondPortfolioPage'}/>, title: 'На металле/бетоне'},
		];
		return (
			<div className="cont">
				<PortfolioBlockHeader adaptive = {adaptive}/>
				<PortfolioBlockPages pages = {pages} adaptive = {adaptive}/>
				<PortfolioBlockAdditionalText adaptive = {adaptive}/>
				<AdaptiveBottomExposureRow/>
				<PortfolioBlockAdditionalText title = 'При заказе мебели вместе с лестницей скидка до 10%' adaptive = {adaptive}/>
			</div>
		);
	}
}