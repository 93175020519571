import React from 'react';
import ReactDOM from 'react-dom';
import {PriceSection} from './priceSection';
import {AdaptivePriceSection} from './priceSection';
import {PortfolioSection} from './portfolioSection';
import {AdaptivePortfolioSection} from './portfolioSection';


// ========================================

if (document.getElementById('priceSection')) {
	ReactDOM.render(
		<PriceSection/>,
		document.getElementById('priceSection')
	);
}

if (document.getElementById('portfolioSection')) {
	ReactDOM.render(
		<PortfolioSection/>,
		document.getElementById('portfolioSection')
	);
}

if (document.getElementById('adaptivePriceSection')) {
	ReactDOM.render(
		<AdaptivePriceSection/>,
		document.getElementById('adaptivePriceSection')
	);
}

if (document.getElementById('adaptivePortfolioSection')) {
	ReactDOM.render(
		<AdaptivePortfolioSection/>,
		document.getElementById('adaptivePortfolioSection')
	);
}