import React, { Component } from "react";
import './index.css';

let fontFamilyStyleMain = {fontFamily: 'TTNorms Bold'};
let textSpanStyleSmall = {fontSize: 18};
let textSpanStyleBig = {fontSize: 24};
let textSpanColorStyle = {color: "rgb(0, 0, 0)"};
let smallTextLineHeight = {lineHeight: 0.9};
let pTextableStyle = {
	textAlign: 'center',
	lineHeight: 1
};

/**
 * Здоровые заголовочные надписи
 */
class HugeTextBlock extends Component {
	render() {
		let adaptive = !!this.props.adaptive;
		let headerStyle = {
			width: 6,
			backgroundColor: '#6DAB3C'
		};
		let textStyle = {
			fontSize: adaptive ? 24 : 56,
			color: '#293520',
			fontFamily: 'TTNorms Bold'
		};
		let text = this.props.text ? this.props.text : 'Заголовок';

		//<div className="node node108 widget widget-text">
		return (
			<div className="node node108 widget widget-text">
				<div className="wrapper1">
					<div className="wrapper2">
						<div className="xs-scale-80 flex">
							<div className="left-line" style={headerStyle}></div>
							<h1 className="font-header spans textable" style={{padding: 16}}>
	              <span className="p">
		              <span style={textStyle}>
			              {text}
	                </span>
	              </span>
							</h1>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

/**
 * Кнопка получения консультации
 */
export class ConsulatationButton extends React.Component {
	render() {
		let textAlignCenterStyle = {textAlign: 'center'};
		let fontSizeSmallStyle = {fontSize: 20};
		let fontSizeBigStyle = {fontSize: 26};

		let buttonText = this.props.text ? this.props.text : 'ПОЛУЧИТЬ КОНСУЛЬТАЦИЮ';
		let product = this.props.product ? this.props.product : '';

		//<div className="node node125 widget widget-button">

		return (
			<div className="node node125 widget widget-button">
				<div className="wrapper1">
					<div className="wrapper2">
						<div className="macros-button">
							<div className="btn-out center xs-none">
								<div className="btn-inner">
									<button className="btn font-header" id="uid23" data-id=""
									        data-action="modal" data-ym_goal="" data-ga_category=""
									        data-ga_action="" need_hover="false" data-modal="form"><span
										className="text">{buttonText}</span></button>
								</div>
							</div>
						</div>
						<div className="macros-modal">
							<div className="modal fade nocolors" data-modal="form" area-context="uid24">
								<div className="modal-dialog" style={{width: 400}}>
									<div className="modal-content">
										<div className="modal-header">
											<button className="close nofonts">×</button>
											<h4 className="textable"><p style={textAlignCenterStyle}><span
												style={fontSizeBigStyle}><span
												style={fontFamilyStyleMain}>ПОЛУЧИТЬ</span></span><br/><span
												style={fontSizeSmallStyle}><span
												style={fontFamilyStyleMain}>КОНСУЛЬТАЦИЮ МАСТЕРА</span></span>
											</p></h4>
										</div>
										<div className="modal-body">
											<div className="macros-form">
												<div className="outer">
													<div className="inner">
														<div className="vertical none size-default">
															<div className="body">
																<div className="cont">
																	<div className="node node126 widget widget-text">
																		<div className="wrapper1">
																			<div className="wrapper2">
																				<div className="">
																					<div className="xs-force-center textable">
																						<p style={textAlignCenterStyle}>
																							<span style={fontSizeSmallStyle}><strong>В течение 15 минут Вам позвонит наш мастер, уточнит детали и поможет сориентироваться в ценах.&nbsp;</strong></span>
																						</p>
																						<p style={textAlignCenterStyle}>
																							<strong>Это ни к
																								чему вас не
																								обязывает</strong>
																						</p></div>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
																<form className="form text-top"
																      data-form='{"name":"Получить консультацю","type":"order","privacy":"none","privacy_checkbox":"Даю согласие на <обработку персональных данных>","after":"url","msg":"Спасибо!\nВаша заявка отправлена.\nВ ближайшее время мы с Вами свяжемся!","url":"/thankyou","addhtml":"","js":"alert(\"Этот код выполняется после успешного отправления заявки.\");","integrations":[]}'
																      data-fields='[{"name":"Номер телефона:","type":"phone","required":true,"id":""},
																      {"name":"Интересующий продукт:","type":"product_type","id":""}]'>
																	<div className="fields">
																		<div className="field"
																		     data-type="phone">
																			<div className="name">Введите номер
																				телефона:<span
																					className="required">&nbsp;*</span>
																			</div>
																			<div className="input"><input
																				className="form-control text"
																				style={{borderRadius: 5}}/>
																			</div>
																		</div>
																		<div className="field"
																		     data-type="product_type">
																			<div className="input">
																				<input className="form-control text hidden" defaultValue = {product}/>
																			</div>
																		</div>
																	</div>
																	<div className="macros-button">
																		<div className="btn-out full xs-none">
																			<div className="btn-inner">
																				<button className="btn font-text submit"
																				        id="uid25"
																				        data-ym_goal="consult"
																				        data-ga_category=""
																				        data-ga_action=""
																				        need_hover="false">
																					<span className="text">{buttonText}</span><input
																					type="submit"/>
																				</button>
																			</div>
																		</div>
																	</div>
																</form>
																<div className="cont"/>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

class BlankSpace extends React.Component {
	render() {
		return (
			<div className="node node124 widget widget-blank">
				<div className="wrapper1">
					<div className="wrapper2">
						<div className="padding"/>
					</div>
				</div>
			</div>
		);
	}
}


/**
 * блок табличного вида "название | цена"
 */
class TextGrid extends React.Component {
  renderRows(data) {
    let grid = [];
    let leftRows = [];
    let rightRows = [];

    let gridDelimeterStyle = {
      borderRight: "1px solid #227835",
	    marginRight: 20,
      left: -0.25
    };

    data.forEach((item, index) => {
      leftRows.push(
	      <p key={item.name+'rightRow'+index} style={smallTextLineHeight}>
          <span style={textSpanStyleSmall}>
            <span style={textSpanColorStyle}>
              {item.name}
            </span>
          </span>
	      </p>
      );
      rightRows.push(
	      <p key={item.name+'leftRow'+index}  style={smallTextLineHeight}>
          <span style={textSpanStyleSmall}>
            <span style={textSpanColorStyle}>
              {item.price}
            </span>
          </span>
	      </p>
      );
    });

    grid.push(
	    <div key={'TextGridInner'+data.length+Math.random()} className="node node133 widget widget-grid">
		    <div className="wrapper1">
			    <div className="wrapper2">
				    <div className="grid valign-middle paddings-0px xs-wrap">
				      <div className="gridwrap">
				        <div className="col" style = {{width: '100%'}}>
				          <div className="cont">
				            <div className="node node134 widget widget-text">
				              <div className="wrapper1">
				                <div className="wrapper2">
				                  <div className="">
				                    <div className="xs-force-center textable">
				                        {leftRows}
				                    </div>
				                  </div>
				                </div>
				              </div>
				            </div>
				          </div>
				        </div>
				        <div className="col" style = {{width: '100%'}}>
				          <div className="grid-delimiter" style={gridDelimeterStyle}/>
				          <div className="cont">
				            <div className="node node135 widget widget-text">
				              <div className="wrapper1">
				                <div className="wrapper2">
				                  <div className="">
				                    <div className="xs-force-center textable">
				                        {rightRows}
				                    </div>
				                  </div>
				                </div>
				              </div>
				            </div>
				          </div>
				        </div>
				      </div>
				    </div>
			    </div>
		    </div>
	    </div>);
    return grid;
  }
  render() {
    let data = this.props.data ? this.props.data : [];
    return (
      <div className="node node133 widget widget-grid">
        {this.renderRows(data)}
      </div>
      );
  }
}

/**
 * абстрактный блок "картинка + наименование + текст"
 * из таких собираем ряды
 */
class ExposureBlock extends React.Component {
	renderImage (img, style) {
		return !img ? [] : (
				<div className="wrapper1">
					<div className="wrapper2">
						<div className="bgimage"
						     style={style}
						     data-lazy-bgimage={img}
						     data-lazy-bgimage_size="376,334">
						</div>
					</div>
				</div>
		);
	}
	renderNameText(string) {
		let adaptive = this.props.adaptive;
		let fontSizeStyle = {fontSize: (this.props.adaptive ? 18 : 24)};
		return !string.length ? [] :(
			<p style = {pTextableStyle}>
				<span style = {fontSizeStyle}>
					<span style = {textSpanColorStyle}>
						<span style = {fontFamilyStyleMain}>
							{string}
						</span>
					</span>
				</span>
			</p>
		);
	}

	renderPirceText(string) {
		let adaptive = this.props.adaptive;
		let fontSizeStyle ={fontSize: (this.props.adaptive ? 20 : 18)}
		let rendered = [];
		if (string.length) {
			rendered.push(
				<p key = {'priceText'+string.length+Math.random()}style = {pTextableStyle}>
					<span style = {fontSizeStyle}>
						<span style = {textSpanColorStyle}>
							{string}
						</span>
					</span>
				</p>);
		}
		return rendered;
	}

	renderTextGrid(data){
		return  data.length ? [<TextGrid key={'TextGrid'+data.length+Math.random()} data={data}/>] : [];
	}

	render() {
		let img = this.props.img ? this.props.img : ''; // '/img/4fe7bc49a67037cf864fbf5d3848c0f2.png
		let nameString = this.props.name ? this.props.name : ''; //'Цельнодеревянная';
		let priceString = this.props.price ? this.props.price : ''; //'от 75 000 руб'
		let textGrid = this.props.textGrid ? this.props.textGrid : []; // [{name: 'прямая', price: 'от 100 000 руб'}];
		let adaptive = !!this.props.adaptive;
		let nodeNumber = adaptive ? 671 : 110;
		let elemntClassName = `node node${nodeNumber} widget widget-element`;
		let imageClassName = `node node${++nodeNumber} widget widget-image`;
		let textClassName = `node node${++nodeNumber} widget widget-text`;
		let bgimageStyle = {
			height: adaptive ? 78 : 167,
			backgroundPosition: 'center center',
			backgroundSize: adaptive ? 'contain' : this.props.contain ? 'contain' : 'cover',
			backgroundImage: "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAOCAYAAAAmL5yKAAAAHElEQVQ4T2NkoBAwUqifYdQAhtEwYBgNA1A+AgAeNwAPNOHi0AAAAABJRU5ErkJggg==')"
		};
		let colStyle = {width: adaptive ? '100%' : '100%'};

		return (
			<div className="col" style={colStyle}>
				<div className="cont">
					<div className={elemntClassName}>
						<div className="wrapper1">
							<div className="wrapper2">
								<div className="cont">
									<div className={imageClassName}>
										{this.renderImage(img, bgimageStyle)}
									</div>
									<div className={textClassName}>
										<div className="wrapper1">
											<div className="wrapper2">
												<div className="">
													<div className="xs-force-center textable">
														{this.renderNameText(nameString)}
														{this.renderPirceText(priceString)}
													</div>
												</div>
											</div>
										</div>
									</div>
									{this.renderTextGrid(textGrid)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

/**
 * абстрактный ряд из блоков <ExposureBlock />
 * из таких собираем конкретные ряды
 */
export class ExposureRow extends React.Component {
	renderBlocks(data) {
		let blocks = [];
		let adaptive = !!this.props.adaptive;
		data.forEach((item, index) => {
			blocks.push(<ExposureBlock adaptive={adaptive} key={'ExposureBlock'+index+Math.random()}{...item}/>);
		});
		return blocks;
	}

	render() {
		let data = this.props.data;
		let adaptive = !!this.props.adaptive;

		//<div className="node node212 widget widget-grid">

		return (
				<div className="wrapper1">
					<div className="wrapper2">
						<div className="grid valign-middle paddings-20px">
							<div className="gridwrap">
								{this.renderBlocks(data, adaptive)}
							</div>
						</div>
					</div>
				</div>
		);
	}
}

/**
 * ряд №1 из абстрактного блока <ExposureRow >
 */
class FirstExposureRow extends React.Component {

  render() {
    let className = this.props.className ? this.props.className : 'node node212 widget widget-grid';
    let data = [
      {img: '/img/2-1.jpg', name: 'Цельнодеревянная', price: 'от 70 000 руб'},
      {img: '/img/2-2.jpg', name: 'Металлокаркас', price: 'от 40 000 руб'},
      {img: '/img/2-3.jpg', name: 'Обшивка металла', price: 'от 105 000 руб'},
      {img: '/img/2-4.jpg', name: 'Обшивка бетонной', price: 'от 115 000 руб'}
    ];

    return (
      <div className={className}>
        <ExposureRow data={data}/>
      </div>
    );
  }
}

class SecondExposureRow extends React.Component {

	render() {
		let className = this.props.className ? this.props.className : 'node node212 widget widget-grid';
		let firstTextGrid = [
			{name: 'прямая', price: 'от 65 000 руб'},
			{name: 'поворотная на 90', price: 'от 75 000 руб'},
			{name: 'поворотная на 180', price: 'от 80 000 руб'},
			{name: 'на металлокаркасе', price: 'от 95 000 руб'},
			{name: ' ', price: ''}
		];
		let secondTextGrid = [
			{name: 'прямая', price: 'от 70 000 руб'},
			{name: 'поворотная на 90', price: 'от 85 000 руб'},
			{name: 'поворотная на 180', price: 'от 98 000 руб'},
			{name: 'на металлокаркасе', price: 'от 115 000 руб'},
			{name: 'на бетоне', price: 'от 105 000 руб'}
		];
		let thirdTextGrid = [
			{name: 'прямая', price: 'от 60 000 руб'},
			{name: 'поворотная на 90', price: 'от 75 000 руб'},
			{name: 'поворотная на 180', price: 'от 85 000 руб'},
			{name: 'на металлокаркасе', price: 'от 105 000 руб'},
			{name: 'на бетоне', price: 'от 98 000 руб'}
		];
		let dataFirst = [
			{img: '/img/3-1.jpg', name: 'Сосна', textGrid: firstTextGrid},
			{img: '/img/3-2.jpg', name: 'Сосна+бук', textGrid: secondTextGrid},
			{img: '/img/3-3.jpg', name: 'Берёза', textGrid: thirdTextGrid}
		];

		let fourthTextGrid = [
			{name: 'прямая', price: 'от 75 000 руб'},
			{name: 'поворотная на 90', price: 'от 85 000 руб'},
			{name: 'поворотная на 180', price: 'от 98 000 руб'},
			{name: 'на металлокаркасе', price: 'от 115 000 руб'},
			{name: 'на бетоне', price: 'от 105 000 руб'}
		];
		let fifthTextGrid = [
			{name: 'прямая', price: 'от 65 000 руб'},
			{name: 'поворотная на 90', price: 'от 75 000 руб'},
			{name: 'поворотная на 180', price: 'от 80 000 руб'},
			{name: 'на металлокаркасе', price: 'от 95 000 руб'},
			{name: ' ', price: ''}
		];
		let sixthTextGrid = [
			{name: 'прямая', price: 'от 95 000 руб'},
			{name: 'поворотная на 90', price: 'от 105 000 руб'},
			{name: 'поворотная на 180', price: 'от 115 000 руб'},
			{name: 'на металлокаркасе', price: 'от 135 000 руб'},
			{name: 'на бетоне', price: 'от 15 000 руб'}
		];
		let dataSecond = [
			{img: '/img/3-4.jpg', name: 'Лиственница', textGrid: fourthTextGrid},
			{img: '/img/3-5.jpg', name: 'Ясень', textGrid: fifthTextGrid},
			{img: '/img/3-6.jpg', name: 'Дуб', textGrid: sixthTextGrid}
		];

		return (
			<div className={className}>
				<ExposureRow data={dataFirst}/>
				<ExposureRow data={dataSecond}/>
			</div>
		);
	}
}

class ThirdExposureRow extends React.Component {

	render() {
		let className = this.props.className ? this.props.className : 'node node212 widget widget-grid';
		let data = [
			{img: '/img/5-1.png', name: 'Прямая', contain: true},
			{img: '/img/5-2.png', name: 'Г-образная', contain: true},
			{img: '/img/5-3.png', name: 'П-образная', contain: true},
			{img: '/img/5-4.png', name: 'Винтовая', contain: true}
		];

		return (
			<div className={className}>
				<ExposureRow data={data}/>
			</div>
		);
	}
}

class FourthExposureRow extends React.Component {

	render() {
		let className = this.props.className ? this.props.className : 'node node212 widget widget-grid';
		let data = [
			{img: '/img/6-1.jpg', name: 'Гостиницы'},
			{img: '/img/6-2.jpg', name: 'Ресторан'},
			{img: '/img/6-3.jpg', name: 'Коттедж'},
			{img: '/img/6-4.jpg', name: 'Квартира'},
			{img: '/img/6-5.jpg', name: 'Дача'},
			{img: '/img/6-6.jpg', name: 'Баня'}
		];

		return (
			<div className={className}>
				<ExposureRow data={data}/>
			</div>
		);
	}
}

/**
 * рендер полного блока "Цены"
 */
export class PriceSection extends React.Component {
	render() {
		return (
			<div className="cont">
				<HugeTextBlock text = 'Виды лестниц, которые мы делаем' />
				<FirstExposureRow />
				<BlankSpace />
				<ConsulatationButton />
				<HugeTextBlock text = 'Из качественных материалов' />
				<SecondExposureRow />
				<BlankSpace />
				<ConsulatationButton />
				<HugeTextBlock text = 'Виды конструкций' />
				<ThirdExposureRow />
				<BlankSpace />
				<ConsulatationButton />
				<HugeTextBlock text = 'Лестницы' />
				<FourthExposureRow />
				<BlankSpace />
				<ConsulatationButton />
			</div>
		);
	}
}

class AdaptiveFirstExposureRow extends React.Component {

	render() {
		let className = this.props.className ? this.props.className : 'node node670 widget widget-grid';
		let adaptive = false;
		let firstRowData = [
			{img: '/img/2-1.jpg'},
			{name: 'Цельнодеревянная', price: 'от 70 000 руб'}
		];

		let secondRowData = [
			{img: '/img/2-2.jpg'},
			{name: 'Металлокаркас', price: 'от 40 000 руб'}
		];

		let thirdRowData = [
			{img: '/img/2-3.jpg'},
			{name: 'Обшивка металла', price: 'от 115 000 руб'}
		];

		let fourthRowData = [
			{img: '/img/2-4.jpg'},
			{name: 'Обшивка бетонной', price: 'от 120 000 руб'}
		];

		return (
			<div className={className}>
				<ExposureRow adaptive = {adaptive} data={firstRowData}/>
				<ExposureRow adaptive = {adaptive} data={secondRowData}/>
				<ExposureRow adaptive = {adaptive} data={thirdRowData}/>
				<ExposureRow adaptive = {adaptive} data={fourthRowData}/>
			</div>
		);
	}
}

class AdaptiveSecondExposureRow extends React.Component {

	render() {
		let className = this.props.className ? this.props.className : 'node node707 widget widget-grid';
		let adaptive = false;
		let firstRowData = [
			{img: '/img/6-1.jpg', name: 'Гостиницы'},
			{img: '/img/6-2.jpg', name: 'Ресторан'}
			];
		let secondRowData = [
			{img: '/img/6-3.jpg', name: 'Коттедж'},
			{img: '/img/6-4.jpg', name: 'Квартира'}
			];
		let thirdRowData = [

			{img: '/img/6-5.jpg', name: 'Дача'},
			{img: '/img/6-6.jpg', name: 'Баня'}
		];

		return (
			<div className={className}>
				<ExposureRow adaptive = {adaptive} data={firstRowData}/>
				<ExposureRow adaptive = {adaptive} data={secondRowData}/>
				<ExposureRow adaptive = {adaptive} data={thirdRowData}/>
			</div>
		);
	}
}

export class AdaptivePriceSection extends React.Component {
	render() {
		let adaptive = true;
		return (
			<div className="cont">
				<HugeTextBlock adaptive={adaptive} text = 'Виды лестниц, которые мы делаем' />
				<AdaptiveFirstExposureRow />
				<HugeTextBlock adaptive={adaptive} text = 'Лестницы' />
				<AdaptiveSecondExposureRow />
			</div>
		);
	}
}




